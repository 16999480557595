import React from "react";
import {Helmet} from "react-helmet";
export default function Term(){
    return (
        <>
        <br /><br />
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Terms and conditions" />
          <meta property="og:description" content="Terms of use of the Library of the People website."/>
        </Helmet>        
          <div className="py-6 sm:py-12">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
              <div className="mx-auto max-w-md px-4">
              <div className="justify-center row">
            <div className="w-full lg:w-2/3">
                <div className="pb-8 text-center section-title">
                    <div className="m-auto line"></div>
                    <h3 className="title text-purple-500"><span></span> Terms of Use</h3>
                </div> {/* section title */}
            </div>
        </div> {/* row */}
                <div className="divide-y divide-gray-300/50">
                  <div className="space-y-6 py-8 text-base leading-7 text-gray-600 dark:text-white">
                    <h5>
                    Please read these terms and conditions carefully before using this 
                    <span className="font-bold text-purple-500"> official</span> website of 
                    <a className="text-blue-500 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft</a>.
<br/>✤ <b>Conditions of use</b><br/>
Using this website, you certify that you have read and reviewed this Agreement and agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. Arfusoft only grants use and access to this website, its products, and its services to those who have accepted its terms.
<br/>✤ <b>Privacy policy</b><br/>
Before you continue using our website, we advise you to read our  <a className="text-purple-500 hover:text-purple-600 font-bold" href="/policy" >privacy policy</a> regarding our user data collection. It will help you better understand our practices.

<br/>✤ <b>Intellectual property</b><br/>
You agree that all materials, products, and services provided on this website are the property of Arfusoft, its affiliates, directors, officers, employees, agents, suppliers, or licensors, including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Arfusoft's intellectual property in any way, including electronic, digital, or new trademark registrations.
You grant Arfusoft a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. You should contact the company to agree on issues regarding intellectual property claims.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    
      );
}