import React from "react";
import {Helmet} from "react-helmet";
function About() {
  return (
    <>
    <br /><br />
    <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="About" />
          <meta property="og:description" content="Learn more about Arfusoft, our software development company."/>
        </Helmet>     
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
            <div className="justify-center row">
            <div className="w-full lg:w-2/3">
                <div className="pb-8 text-center section-title">
                    <div className="m-auto line"></div>
                    <h3 className="title text-purple-500"><span></span> About Us</h3>
                </div> {/* section title */}
            </div>
        </div> {/* row */}
              <div className="divide-y divide-gray-300/ ">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p className="text-gray-600 dark:text-gray-200">
                  <a className="text-sky-500 hover:text-sky-600 font-bold text-base" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft </a>
                   has years of experience in developing:
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center ">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4 text-gray-600 dark:text-gray-200">
                        Web
                        <code className="text-sm font-bold "> frontend</code> applications
                      </p>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4 text-gray-600 dark:text-gray-200">
                        Web
                        <code className="text-sm font-bold "> backend/server</code> applications
                      </p>
                    </li>
                                <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4 text-gray-600 dark:text-gray-200">
                        <code className="text-sm font-bold ">Mobile</code> applications for Android and IOS
                      </p>
                    </li>
                                            <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4 text-gray-600 dark:text-gray-200">
                        <code className="text-sm font-bold ">Desktop</code> applications for Win and MAC
                      </p>
                    </li>
                  </ul>
                  <p className="text-base text-gray-600 dark:text-gray-200"><span className="text-sky-500 hover:text-sky-600 font-bold text-base">Our area of expertise </span> is <span className="text-purple-500 font-bold text-base">data-intensive fullstack web applications </span>.</p>
                  <p className="text-base text-gray-600 dark:text-gray-200">For more information, please <a className="text-sky-400 hover:text-sky-600 font-bold" href="/contact">contact us</a> .</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

    </>

  );
  }
  export default About;