import Hero from "./landing/Hero";
import Cta from "./landing/Cta";
import WorkFlows from "./landing/WorkFlows";
import Testimonials from "./landing/Testimonials";
import Footer from "./landing/Footer";
import CtaEnd from "./landing/CtaEnd";

function Home () {
    return(
        <>
      <Hero/>
 {/*====== PRELOADER PART START ======*/}

 <div className="hidden preloader">
    <div className="loader">
        <div className="ytp-spinner">
            <div className="ytp-spinner-container">
                <div className="ytp-spinner-rotator">
                    <div className="ytp-spinner-left">
                        <div className="ytp-spinner-circle"></div>
                    </div>
                    <div className="ytp-spinner-right">
                        <div className="ytp-spinner-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<Cta/>
<WorkFlows/>
<CtaEnd/>
<a href="#" className="back-to-top"><i className="lni lni-chevron-up"></i></a>
< div className="py-24"></div>

        </>
  )
}
export default Home;