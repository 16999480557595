
import banner from "../../assets/images/banner-bg.svg";
import hero from "../../assets/images/tech3r.png";




export default function CtaEnd() {
    return (
      <div id="home" className="header-hero" style={{backgroundImage: banner}}>
      <div className="container">
          <div className="justify-center row">
              <div className="w-full lg:w-2/3">
                  <div className="pt-6 mb-12 text-center lg:pt-12 header-hero-content">
                      <h3 className="text-3xl text leading-tight text-sky-600 dark:text-sky-400 header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s"><strong>Act fast. Act now.</strong> </h3><br/>
                      <h4 className="mb-8 text-gray-600 dark:text-gray-400 text wow fadeInUp text-lg" data-wow-duration="1.3s" data-wow-delay="0.8s">
                      Embark on the digital wagon today, or lose ground to your competitors. Wait no more!
                      </h4>
                      <a className="main-btn bg-gradient-to-r from-indigo-600 to-sky-400 page-scroll lg:w-1/3 md:w-1/2 w-full" data-scroll-nav="0" href="/contact" rel="nofollow">Contact Us</a>


                  </div> {/* header hero content */}
              </div>
          </div> {/* row */}
          <div className="justify-center row lg:pl-24 md:pl-12 pl-6">
              <div className="w-full lg:w-2/3">
                  <div className="text-center header-hero-image wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s">
                      <img src={hero} alt="hero"/>
                  </div> {/* header hero image */}
              </div>
          </div> {/* row */}
      </div> {/* container */}
      <div id="particles-1" className="particles"></div>
  </div>
    );
  }